<template>
  <div v-if='status==200' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-green -top-14">
    Great! The file is processing... You can add more below
  </div>
  <div v-else-if='status==202' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-green -top-14">
    File added {{this.isOnboarding ? 'successfully! Taking you to your Spext Drive shortly...' : ''}}
  </div>

  <div v-else-if='billing && (balance===0) && billing.trial' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    <span>
      Free trials exhausted!&nbsp;<a class="text-white underline hover:text-white" @click='buyMinutes'>Subscribe now</a>
    </span>
  </div>

  <div v-else-if='status==400' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg -top-14">
    Problem in accessing the link
  </div>

  <div v-else-if='status==401' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    Hmm, looks like we don't support that format. Try a link that ends with .mp3, .mp4 etc
  </div>

  <div v-else-if='status==402' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    Problem with audio. Sample rate is not defined or lower than 16000 for this file
  </div>

  <div v-else-if='status==500' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    Error in downloading the audio.
  </div>

  <div v-else-if='((balance==0)||(duration>balance) && !billing.trial)' class="absolute left-0 flex items-center justify-between w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    <div>
      Insufficient balance! You have <span class='bold'>{{humanLength(balance)}} remaining</span>
      <span v-show="duration>0"> and required is
        <span class='bold'>{{humanLength(duration)}}</span>
      </span>.
    </div>

    <a class="text-white underline cursor-pointer hover:text-white" @click='buyMinutes'>Buy more minutes?</a>
  </div>

  <div v-else-if='status==501' class="absolute left-0 flex items-center justify-between w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    <div>File is too large to upload. We support files upto 4GB.</div>
    <a
      class="text-white underline hover:text-white"
      onclick="window.Intercom('showNewMessage','File size greater than 4GB')"
      target="_top"
    >
      Contact us
    </a>
  </div>

  <div v-else-if='status==502' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    Error in audio processing, format conversion.
  </div>

  <div v-else-if='status==503' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    Our server is facing a problem in transcribing.
  </div>

  <div v-else-if='status==504' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    File length is more than 3 hrs, Please reduce the length and try again.
  </div>

  <div v-else-if='status==505' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    Can not determine File size
  </div>

  <div v-else-if='status==506' class="absolute left-0 flex items-center justify-between w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
      <div>File is too large to process.</div>
      <a
        class="text-white underline hover:text-white"
        href="mailto:hello@spext.co?Subject=Enterprise%20plan"
        target="_top"
      >
        Contact us for the Enterprise plan!
      </a>
  </div>

  <div v-else-if='status==600' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    Hmm, this seems to be a duplicate link. Try a different one.
  </div>

  <div v-else-if='status==601' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    A file with the same title exists already. Please try a different title.
  </div>

  <div v-else-if='status==603' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    Unknown Error. Please try later.
  </div>

  <div v-else-if='status==604' class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    Cant connect to server
  </div>

  <div v-else-if='status==605' class="absolute left-0 flex items-center justify-start w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    <div>Closing this window will cancel the upload. Cancel?</div>
    <div class="flex items-center">
      <div
        class="font-bold cursor-pointer"
        @click="clear();close();"
      >
        &nbsp;&nbsp;Yes |
      </div>
      <div
        class="cursor-pointer font-bold"
        @click="clearStatus"
      >
        &nbsp;No
      </div>
    </div>
  </div>

  <div v-else-if='status==606' class="absolute left-0 flex items-center justify-between w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    <div>Switching to Multi-Track will clear this Upload. Clear?</div>
    <div class="flex items-center">
      <div
        class="font-bold cursor-pointer"
        @click="clear();selectedMenu='multi'"
      >
        Yes
      </div>
      <div
        class="cursor-pointer font-bold ml-2.5"
        @click="clearStatus"
      >
        No
      </div>
    </div>
  </div>

  <div v-else-if='status==607' class="absolute left-0 flex items-center justify-between w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    <div>Switching to Normal will clear this Upload. Clear?</div>
    <div class="flex items-center">
      <div
        class="font-bold cursor-pointer"
        @click="clear();selectedMenu='normal'"
      >
        Yes
      </div>
      <div
        class="cursor-pointer font-bold ml-2.5"
        @click="clearStatus"
      >
        No
      </div>
    </div>
  </div>

  <div v-else-if="cancelItemId!==''" class="absolute left-0 flex items-center justify-between w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    <div>Cancel Uploading Track {{cancelItemId}}?</div>
    <div class="flex items-center">
      <div
        class="font-bold cursor-pointer"
        @click="cancelUpload"
      >
        Yes
      </div>
      <div
        class="cursor-pointer font-bold ml-2.5"
        @click="clearCancelId"
      >
        No
      </div>
    </div>
  </div>

  <div v-else-if="totalDuration>balance" class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    <p>
      You need {{humanLength(totalDuration-balance)}} more to process all files.
      <a
        @click='buyMinutes'
        class="text-white underline hover:text-white"
      >
      Get more minutes
      </a> or
      <a
        @click="setShowSupportedFiles(true)"
        class="text-white underline hover:text-white"
      >
        Choose files to Upload
      </a>
    </p>
  </div>

  <div v-else-if="status===700" class="absolute left-0 w-full px-4 py-3 text-sm text-white rounded-lg bg-red -top-14">
    You seem to be offline, please try uploading later.
  </div>
</template>

<script>
import { humanLength } from '../../utilities/utility'
import store from '@/services/store'
export default {
  props: ['status', 'duration', 'cancelItemId', 'totalDuration'],
  methods: {
    humanLength (timeInSec) {
      return humanLength(timeInSec)
    },
    cancelUpload () {
      this.$emit('cancelUpload')
    },
    buyMinutes () {
      this.$emit('buyMinutes')
    },
    clear () {
      this.$emit('clear')
    },
    close () {
      this.$emit('close')
    },
    clearStatus () {
      this.$emit('clearStatus')
    },
    clearCancelId () {
      this.$emit('clearCancelId')
    },
    setShowSupportedFiles (payload) {
      this.$emit('setShowSupportedFiles', payload)
    }
  },
  computed: {
    billing () {
      return store.state.app.userInfo.billing
    },
    balance () {
      return store.state.app.userInfo.billing.seconds
    },
    isOnboarding() {
      return window.location.pathname === '/onboarding'
    }
  }
}
</script>
<style scoped>

</style>
